<!--
 * @Author: wankong
 * @Desc: 
 * @Date: 2021-11-04 08:42:28
 * @LastEditTime: 2021-11-04 09:17:08
-->
<template>
  <div class="works">
    <div v-title data-title="一些小工具"></div>
    <MHeader></MHeader>
    <div
      class="main"
      :style="
        'background-image: url(' + this.$store.state.config.web_bgimg + ')'
      "
    >
      <ul>
        <li></li>
      </ul>
    </div>
    <back-top></back-top>
  </div>
</template>

<script>
import BackTop from "@/components/common/BackTop.vue";
import MHeader from "@/components/common/Header.vue";
export default {
  components: {
    MHeader,
    BackTop,
  },
  data() {
    return {
      toolList: [
        {
          name: "在线音乐播放器",
          auth: "Mk",
          bgcolor: "",
          url: "http://m.mmifx.com",
          desc: "一款在线音乐播放器，虽然很久没维护了，但是接口依然坚挺",
        },

        {
          name: "视频播放器",
          auth: "未知",
          bgcolor: "",
          url: "http://p.mmifx.com",
          desc: "某位老哥写的仿bilibili播放器，支持mp4/m3u8在线播放，支持记忆播放，发送弹幕等等...",
        },
        {
          name: "意刻搜题站",
          auth: "晚空",
          bgcolor: "",
          url: "http://sou.mmifx.com",
          desc: "自己写的一个搜题管理系统，大概有42万条题库数据，来自各种乱七八糟的平台，系统仍处于试运行阶段",
        },
        {
          name: "茗门爱分享",
          auth: "晚空",
          bgcolor: "",
          url: "http://sou.mmifx.com",
          desc: "一款免费的网课搜题微信小程序，也集成了自己的几个小工具，现在只上线了图书馆签到链接获取。",
        },
        {
          name: "影视小程序",
          auth: "晚空",
          bgcolor: "",
          url: "http://www.wankong.top/",
          desc: "",
        },
      ],
    };
  },
  methods: {},
  created() {},
};
</script>

<style lang="less" scoped>
</style>